import jquery from "jquery";
window.$ = window.jQuery = jquery;

function navAnim() {
	$(window).scroll(function() {    
		var scroll = $(window).scrollTop();
		var $win = $(window);
		var winH = $win.height();   // Get the window height
	
		 //>=, not <=
		if (scroll > winH) {
			//clearHeader, not clearheader - caps H
			// $(".page-header").addClass("slimmer");
			$(".popout-menu").addClass("show");
		} else {
			// $(".page-header").removeClass("slimmer");
			$(".popout-menu").removeClass("show");
		}

		// // Acquire New Customers Section
		// var distance = $('.aquire-new-customers').offset().top;

		// if ( $(this).scrollTop() > distance ) {
		// 	newCustomers();
		// 	$(window).off('scroll');
		// } else {
			
		// }

	}); //missing );

	// Learn More Button
	$(".popout-open-btn").on("click", function () {
		$(".popout-menu").addClass("active");
		setTimeout(function () {
		  $(".popout-menu").addClass("height");
		}, 200);
	  });
	  $(".popout-close-btn").on("click", function () {
		$(".popout-menu").removeClass("active height");
	  });

  // Mobile Menu on/off 
  
  // $(".menu").on("click", function () {
	// 	$(".main-nav").addClass("active");
	// });
  // $(".main-nav a").on("click", function () {
  //   $(".main-nav").removeClass("active");
  // });

// New Mobile Menu JS

$(".hamburger").on("click",function(){
  $(this).toggleClass("is-active");
  $("body").toggleClass("mobile-overflow");
  $(".main-nav").toggleClass("active");
  $(".main-nav ul li a").click(function(){
    $(".main-nav").removeClass("active");
    $(".hamburger").removeClass("is-active");
    $("body").removeClass("mobile-overflow");
  });
});

  // $(".close-menu").on("click", function () {
  //   $(".main-nav").removeClass("active");
  // });

}
export default navAnim;

function IsMouseOver(oi) {
    return $(oi).is(":hover");
  }

  var inactivityTime = function () {
    var time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    document.onmousedown = resetTimer;
    document.ontouchstart = resetTimer;
    document.onclick = resetTimer;
    document.onkeydown = resetTimer;
    document.addEventListener("scroll", resetTimer, true);

    var bottom = $(".section-breakpoint").eq(0).offset().top;

    function logout() {
      if ($(window).scrollTop() > bottom) {
        $(".get-in-touch").addClass("active");
      }
      //location.href = 'logout.html'
    }

    function resetTimer() {
      clearTimeout(time);
      setTimeout(() => {
        if (!IsMouseOver(".get-in-touch")) {
          $(".get-in-touch").removeClass("active");
        }
      }, 2000);
      time = setTimeout(logout, 2000);
    }
  };

  inactivityTime();

// Aquire New Customers

// function newCustomers() {

// 	window.setTimeout(function(){
// 		$(".aquire-new-customers .callouts-wrapper .callout01").addClass("highlight");
// 	},6000);
// 	window.setTimeout(function(){
// 		$(".aquire-new-customers .callouts-wrapper .callout01").removeClass("highlight");
// 		$(".aquire-new-customers .callouts-wrapper .callout02").addClass("highlight");
// 	},9000);

// 	window.setTimeout(function(){
// 		$(".aquire-new-customers .callouts-wrapper .callout02").removeClass("highlight");
// 		$(".aquire-new-customers .callouts-wrapper .callout03").addClass("highlight");
// 	},12000);

// 	window.setTimeout(function(){
// 		$(".aquire-new-customers .callouts-wrapper .callout03").removeClass("highlight");
// 		$(".aquire-new-customers .callouts-wrapper .callout04").addClass("highlight");
// 	},15000);
// 	window.setTimeout(function(){
// 		$(".aquire-new-customers .callouts-wrapper .callout04").removeClass("highlight");
// 		$(".aquire-new-customers .callouts-wrapper .callout05").addClass("highlight");
// 	},18000);
// 	window.setTimeout(function(){
// 		$(".aquire-new-customers .callouts-wrapper .callout05").removeClass("highlight");
// 	},21000);

// }