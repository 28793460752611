import highway from "@dogstudio/highway";
import textReveal, { DestroyScrollMagicAnimation } from "./textreveal";
import Fade from "./transition";
import typeEffect from "./typingeffect";
import navAnim from "./navanimation";
import calloutAnim from "./acquirecustomers";
import slickCarousel from "./slickcarousel";
import AOS from 'aos';
import 'aos/dist/aos.css';
import parallaxInit from "./simpeparallax";
// import formDisplay  from "./form";
AOS.init({
    once: true,
    // disable: function() {
    //     var maxWidth = 1024;
    //     return window.innerWidth <= maxWidth;
    //   }
});
textReveal();
parallaxInit();
navAnim();
slickCarousel();
calloutAnim();
// formDisplay();



console.log('hello')

// typeEffect();
// destroyAOS();


const H = new highway.Core({
    transitions: {
        default: Fade
    }
});

const links = document.querySelectorAll('nav a');

H.on('NAVIGATE_IN', ({ to, location }) => {
    links.forEach(link => {
        link.classList.remove('active');
        $(".popout-menu").removeClass("active height");
        if (link.href === location.href) {
            link.classList.add('active');
            DestroyScrollMagicAnimation();
            setTimeout(function () {
                textReveal();
                AOS.init({
                    once: true,
                    // disable: function() {
                    //     var maxWidth = 1024;
                    //     return window.innerWidth <= maxWidth;
                    //   }
                });
                slickCarousel();
                parallaxInit();
                // to recall animation on nav switch
                window.executed = false;
                // to stop all callout animations on nav switch
                clearTimeout(window.animation1);
                clearTimeout(window.animation2);
                clearTimeout(window.animation3);
                clearTimeout(window.animation4);
                clearTimeout(window.animation5);
                clearTimeout(window.animation6);
            }, 50);
        }
        // if(link.href.includes('aquisition')) {

        //     $(".menu-link.pdfdownload-link").click(function() {
        //         window.open("./aquisition-page.pdf",'_blank');
        //     });
        // }
    })
});

$(".learn-more").on("click", function() {
//   alert("test form");
  $("#thankyou").hide();
  $("#contactForm").show();
  $("#form-modal").addClass("active");
  $(".right-column").eq(0).css("height", $(".right-column")[0].offsetHeight);
  grecaptcha.reset($(".g-recaptcha"));
  document.body.style.overflow = "hidden";
});
