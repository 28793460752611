import jquery from "jquery";

window.$ = window.jQuery = jquery;

function calloutAnim() {
	$(window).scroll(function() {    

		// Acquire New Customers Section
		var distance = $('.aquire-new-customers').offset().top;

		if ( $(this).scrollTop() > distance ) {
			newCustomers();
			// $(window).off('scroll');
		} else {
			
		}

	}); //missing );
}

// Aquire New Customers

var newCustomers = (function() {
    window.executed = false;
    return function() {
        if (!executed) {
			
            window.executed = true;
			
			window.animation1 = window.setTimeout(function(){
			$(".aquire-new-customers .callouts-wrapper .callout01").addClass("highlight");
			},5500);

			window.animation2 = window.setTimeout(function(){
				$(".aquire-new-customers .callouts-wrapper .callout01").removeClass("highlight");
				$(".aquire-new-customers .callouts-wrapper .callout02").addClass("highlight");
			},8000);

			window.animation3 = window.setTimeout(function(){
				$(".aquire-new-customers .callouts-wrapper .callout02").removeClass("highlight");
				$(".aquire-new-customers .callouts-wrapper .callout03").addClass("highlight");
			},10500);

			window.animation4 = window.setTimeout(function(){
				$(".aquire-new-customers .callouts-wrapper .callout03").removeClass("highlight");
				$(".aquire-new-customers .callouts-wrapper .callout04").addClass("highlight");
			},13000);

			window.animation5 = window.setTimeout(function(){
				$(".aquire-new-customers .callouts-wrapper .callout04").removeClass("highlight");
				$(".aquire-new-customers .callouts-wrapper .callout05").addClass("highlight");
			},15500);

			window.animation6 = window.setTimeout(function(){
				$(".aquire-new-customers .callouts-wrapper .callout05").removeClass("highlight");
			},18000);
		}
    };
})();

// function newCustomers() {

// 	window.setTimeout(function(){
// 		$(".aquire-new-customers .callouts-wrapper .callout01").addClass("highlight");
// 	},6000);
// 	window.setTimeout(function(){
// 		$(".aquire-new-customers .callouts-wrapper .callout01").removeClass("highlight");
// 		$(".aquire-new-customers .callouts-wrapper .callout02").addClass("highlight");
// 	},9000);

// 	window.setTimeout(function(){
// 		$(".aquire-new-customers .callouts-wrapper .callout02").removeClass("highlight");
// 		$(".aquire-new-customers .callouts-wrapper .callout03").addClass("highlight");
// 	},12000);

// 	window.setTimeout(function(){
// 		$(".aquire-new-customers .callouts-wrapper .callout03").removeClass("highlight");
// 		$(".aquire-new-customers .callouts-wrapper .callout04").addClass("highlight");
// 	},15000);
// 	window.setTimeout(function(){
// 		$(".aquire-new-customers .callouts-wrapper .callout04").removeClass("highlight");
// 		$(".aquire-new-customers .callouts-wrapper .callout05").addClass("highlight");
// 	},18000);
// 	window.setTimeout(function(){
// 		$(".aquire-new-customers .callouts-wrapper .callout05").removeClass("highlight");
// 	},21000);

// }

export default calloutAnim;