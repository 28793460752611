// Slick Carousel
import 'slick-carousel'

function slickCarousel() {

  $(".slider01-text").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    asNavFor: '.slider01-img',
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false
  });
  $('.slider01-img').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.slider01-text',
    dots: false,
    arrows: false,
    pauseOnHover: false
  });
  
  $('.slider02-img').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    dots: true
  });

  $('.slider03-img').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    dots: true
  });

  // For setting cursor on dragging
  $(".draggable").on("mousedown", function () {
    $(this).addClass("mouseDown");
  }).on("mouseup", function () {
    $(this).removeClass("mouseDown");
  });

  // For making AOS work in slider 01
  var currentSlider01 = 0;
  $('.slider01-text' )
  .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    currentSlider01 = currentSlide;
      
      if (nextSlide === currentSlide ) {} else {
      $('.slidetext01 h4').removeClass("aos-animate");
      $('.slidetext01 h3').removeClass("aos-animate");
      $('.slidetext01 p.step-info').removeClass("aos-animate");
      }
      console.log({currentSlide, nextSlide})
  })
  .on('afterChange', function(event, slick, currentSlide, nextSlide) {
              
        if (currentSlider01 === currentSlide ) {} else {
          $('.slidetext01 h4').addClass("aos-animate");
          $('.slidetext01 h3').addClass("aos-animate");
          $('.slidetext01 p.step-info').addClass("aos-animate");
        }
  });

  // For making AOS work in slider 02
  var currentSlider02 = 0;
  $('.slider02-img' )
  .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    currentSlider02 = currentSlide;
      
      if (nextSlide === currentSlide ) {} else {
      $('.slidetext02 h3').removeClass("aos-animate");
      $('.slidetext02 p').removeClass("aos-animate");
      }
      console.log({currentSlide, nextSlide})
  })
  .on('afterChange', function(event, slick, currentSlide, nextSlide) {
       
        if (currentSlider02 === currentSlide ) {} else {
          $('.slidetext02 h3').addClass("aos-animate");
          $('.slidetext02 p').addClass("aos-animate");
        }
  });

  // For making AOS work in slider 03

  var currentSlider03 = 0;
  $('.slider03-img' )
  .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    currentSlider03 = currentSlide;
      
      if (nextSlide === currentSlide ) {} else {
      $('.slidetext03 h3').removeClass("aos-animate");
      $('.slidetext03 p').removeClass("aos-animate");
      }
      console.log({currentSlide, nextSlide})
  })
  .on('afterChange', function(event, slick, currentSlide, nextSlide) {
       
        if (currentSlider03 === currentSlide ) {} else {
          $('.slidetext03 h3').addClass("aos-animate");
          $('.slidetext03 p').addClass("aos-animate");
        }
  });
  
  // Advantage Slider
  $('.advantages-slider').slick({
    centerMode: true,
    centerPadding: '70px',
    slidesToShow: 4,
    slidesToScroll: 2,
    infinite: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false
        }
      },
    ]
  });

  // News Ticker
  $('.news-ticker').slick({
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerMode: false,
          variableWidth: false,
        }
      },
    ]
  });
}

export default slickCarousel;