// Simple Parallax
import simpleParallax from "simple-parallax-js";

function parallaxInit() {
  const heading = document.querySelectorAll(".acquisition-section .headline h2");
  new simpleParallax(heading, {
	  orientation: 'left',
    overflow: true,
  });
}

export default parallaxInit;