import jquery from "jquery";
import gsap, { TimelineMax, Linear } from "gsap/gsap-core";
import ScrollMagic from "scrollmagic";
import typeEffect from "./typingeffect";

import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Aos from "aos";
window.$ = window.jQuery = jquery;

// debugger
ScrollMagicPluginGsap(ScrollMagic, gsap);
var controller = undefined;
var sc1 = undefined;
function textReveal() {
  var typeEffectAdded = false;
  var windowWidth = $(window).width();
  $(window).scroll(function () {
    var bottom = $(".section-breakpoint").eq(0).offset().top;
    // console.log("test message");
  });

  var inactivityTime = function () {
    var time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    document.onmousedown = resetTimer;
    document.ontouchstart = resetTimer;
    document.onclick = resetTimer;
    document.onkeydown = resetTimer;
    document.addEventListener("scroll", resetTimer, true);

    var bottom = $(".section-breakpoint").eq(0).offset().top;

    function resetTimer() {
      clearTimeout(time);
    }
  };

  inactivityTime();

  function ScrollMagicAnimation() {
    controller = new ScrollMagic.Controller();
    var wipeAnimation1 = new TimelineMax({onComplete: function() {
       if(location.href.includes('aquisition') && !typeEffectAdded) {
         console.log("CALLED")
         setTimeout(() => {
          typeEffectAdded = true;
          typeEffect(); 
         }, 1000);
      }
    }})
      .fromTo(
        ".panel-container-1 .panel-1 .img-1",
        1,
        {
          width: "112%",
          display: "block",
        },
        {
          width: "10000%",
          display: "none",
          ease: Linear.easeIn,
        },
        "0.01"
      )
      .fromTo(
        ".panel-container-1 .header-banner",
        0.1,
        {
          opacity: "0",
        },
        {
          opacity: "1",
          ease: Linear.easeIn,
        },
        "0.01"
      )

      // .call(function(){console.log("this is a new test")}, [".panel-container-1 .panel-1"], this, 0.81)
      // .call(function(){console.log("this is a new test")}, [".panel-container-3 .panel-3"], this, 0.8);

      // .call(redoAOS, [".panel-container-1 .panel-1"], this, 0.81);

      //.call(removeClassActive, [".header-banner .headline"], this, 0.61)
      // .call(addClassActive, [".header-banner .headline"], this, 0.81)

      

    sc1 = new ScrollMagic.Scene({
      triggerElement: ".panel-container-1",
      triggerHook: "onLeave",
      duration: "60%",
    })
      .setPin(".panel-container-1")
      .setTween(wipeAnimation1)
      .addTo(controller);
  }
//   function redoAOS() {
//     console.log('redo AOS')
//         // $(".header-banner .headline, .header-banner .image-wrapper, .header-banner h3, .header-banner p").removeClass("aos-animate");
//  }
 function removeClassActive(_target) {
  // $(_target).removeClass("aos-animate");
  // if(location.href.includes('aquisition') && !typeEffectAdded) {
  //   typeEffectAdded = true;
  //   typeEffect(); 
  // }

  console.log("Called");
}
// function addClassActive(_target) {
//   $(_target).addClass("aos-animate");
// }

  

  ScrollMagicAnimation();

  $(window).on("resize", function () {
    if (windowWidth !== $(window).width()) {
      EnableDisableScrollMagic();
      DestroyScrollMagicAnimation();
      ScrollMagicAnimation();
    }
    windowWidth = $(window).width();
  });

  // EnableDisableScrollMagic();

  function EnableDisableScrollMagic() {
    if ($(window).width() < 1024) {
    //   if (controller.enabled()) {
    //     controller.enabled(false);
    //  //   addClassActive(".panel-container-1 .panel-1");
    // //    addClassActive(".panel-container-1 .panel-1 .title");
    //     sc1.enabled(false);
    //   }
    } else {
      controller.enabled(true);
      sc1.enabled(true);
    }
    controller.update(true);
  }

  function SwipeDetection() {
    document.addEventListener("touchstart", handleTouchStart, false);
    document.addEventListener("touchmove", handleTouchMove, false);

    var xDown = null;
    var yDown = null;

    function getTouches(evt) {
      return (
        evt.touches || // browser API
        evt.originalEvent.touches
      ); // jQuery
    }

    function handleTouchStart(evt) {
      const firstTouch = getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    }

    function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;

      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          /* left swipe */
        } else {
          /* right swipe */
        }
      } else {
        if (yDiff > 0) {
          /* up swipe */
          if ($(window).width() < 1024) {
            slideUpSplash();
          } else {
            $("body").css("overflow-y", "scroll");
          }
        } else {
          /* down swipe */
        }
      }
      /* reset values */
      xDown = null;
      yDown = null;
    }
  }
}
if (location.href.includes("/index.html")) {
  $(window).on("load", function () {
    textReveal();
  });
}


export function DestroyScrollMagicAnimation() {
  controller && controller.destroy(true);
  sc1 && sc1.destroy(true);
  controller = undefined;
  sc1 = undefined;
}
export default textReveal;

// Destroy AOS
// function destroyAOS() {
//   $(".header-banner .headline, .header-banner .image-wrapper, .header-banner h3, .header-banner p").removeClass("aos-animate");
// }
// export default destroyAOS;